import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Alert, 
  Box, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { countries } from '../utils/countries';

function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({ name: '', country: 'United States' });
  const [message, setMessage] = useState({ type: '', text: '' });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    // Fetch user profile
    const fetchProfile = async () => {
      try {
        const response = await api.get('/auth/me');
        setProfile({
          ...response.data,
          country: response.data.country
        });
      } catch (error) {
        setMessage({ type: 'error', text: 'Error fetching profile.' });
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', text: '' });
    try {
      await api.put('/auth/me', profile); 
      setMessage({ type: 'success', text: 'Profile updated successfully.' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error updating profile.' });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await api.delete('/auth/delete-account');
      localStorage.removeItem('authToken'); // Clear auth token
      navigate('/login'); // Redirect to login page
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error.response?.data?.detail || 'Error deleting account.' 
      });
      setDeleteDialogOpen(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      {message.text && <Alert severity={message.type} sx={{ mb: 2 }}>{message.text}</Alert>}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Full Name"
          name="name"
          variant="outlined"
          required
          value={profile.name}
          onChange={handleChange}
        />
        <FormControl fullWidth required>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            label="Country"
            name="country"
            value={profile.country}
            onChange={handleChange}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Update Profile
        </Button>
      </Box>

      <Box sx={{ mt: 6, mb: 4 }}>
        <Divider />
        <Typography variant="h6" color="error" sx={{ mt: 4, mb: 2 }}>
          Danger Zone
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Once you delete your account, there is no going back. Please be certain.
        </Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete Account
        </Button>
      </Box>

      {/* Delete Account Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone and will result in:
            <Box component="ul" sx={{ mt: 1 }}>
              <li>Permanent deletion of your profile</li>
              <li>Removal of all your API keys</li>
              <li>Deletion of your usage history</li>
              <li>Cancellation of any active subscriptions</li>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteAccount} 
            color="error" 
            variant="contained"
          >
            Delete My Account
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Profile;
