import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

function SignupSuccess() {
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
        <Box sx={{ mb: 3 }}>
          <EmailIcon color="success" sx={{ fontSize: 64 }} />
        </Box>
        <Typography variant="h4" gutterBottom>
          Check Your Email
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We've sent you an email with a login link. Click the link to access your account.
        </Typography>
        <Box sx={{ mt: 4, p: 2, bgcolor: 'success.light', borderRadius: 1 }}>
          <Typography variant="body2" color="success.contrastText">
            Tip: If you don't see the email in your inbox, please check your spam folder.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default SignupSuccess;
