import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from '@mui/material';

const ModelMetricsTable = ({ modelStats }) => {
    const formatNumber = (num) => {
        if (num === undefined || num === null) return '0';
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
    };

    const formatDuration = (seconds) => {
        if (seconds === undefined || seconds === null) return '0s';
        return `${seconds.toFixed(2)}s`;
    };

    const formatPercentage = (value) => {
        if (value === undefined || value === null) return '0%';
        return `${(value * 100).toFixed(1)}%`;
    };

    const isEmbeddingModel = (model) => {
        return model === 'snowflake-arctic-embed-l-v2.0';
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Model</TableCell>
                        <TableCell align="right">Requests</TableCell>
                        <TableCell align="right">Success Rate</TableCell>
                        <TableCell align="right">Input TPS</TableCell>
                        <TableCell align="right">
                            <Tooltip title="Only applicable for chat models">
                                <span>Output TPS</span>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="right">Avg Duration</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(modelStats).map(([model, data]) => {
                        const stats = data?.stats || {};
                        const successRate = stats.total_requests > 0
                            ? stats.successful_requests / stats.total_requests
                            : 0;
                        const isEmbedding = isEmbeddingModel(model);

                        return (
                            <TableRow key={model}>
                                <TableCell component="th" scope="row">
                                    {model}
                                </TableCell>
                                <TableCell align="right">
                                    {formatNumber(stats.total_requests)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatPercentage(successRate)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatNumber(stats.average_input_tps)}
                                </TableCell>
                                <TableCell align="right">
                                    {isEmbedding ? 'N/A' : formatNumber(stats.average_output_tps)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatDuration(stats.average_duration)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ModelMetricsTable;
