import axios from 'axios';


const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/api';

export const fetchMetrics = async (timeWindow) => {
    const token = localStorage.getItem('authToken');
    try {
        const response = await axios.post(
            `${API_URL}/metrics/stats`,
            timeWindow,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        if (error.response?.status === 403) {
            throw new Error('Admin access required');
        }
        throw error;
    }
};
