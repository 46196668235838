import React, { useState } from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, Box, Paper, Link, Tabs, Tab } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';
import LowCostIcon from '@mui/icons-material/AttachMoney';
import ModelsIcon from '@mui/icons-material/Memory';
import APIIcon from '@mui/icons-material/Api';
import DocsIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/SupportAgent';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Home() {
  const [selectedLanguage, setSelectedLanguage] = useState('nodejs');

  // Code examples organized by language
  const codeExamples = {
    nodejs: {
      code: `import OpenAI from "openai";
const openai = new OpenAI({
    baseURL: "https://api.llamacloud.co",
    apiKey: "Your-API-Key"
});
const completion = await openai.chat.completions.create({
    model: "mistral-nemo",
    messages: [
        {"role": "user", "content": "write a haiku about ai"}
    ]
});`,
      language: 'javascript'
    },
    python: {
      code: `from openai import OpenAI
client = OpenAI(
    base_url="https://api.llamacloud.co",
    api_key="Your-API-Key"
)
completion = client.chat.completions.create(
    model="mistral-nemo",
    messages=[
        {"role": "user", "content": "write a haiku about ai"}
    ]
)`,
      language: 'python'
    },
    curl: {
      code: `curl https://api.llamacloud.co/chat/completions \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $OPENAI_API_KEY" \\
-d '{
    "model": "mistral-nemo",
    "messages": [
        {"role": "user", "content": "write a haiku about ai"}
    ]
}'`,
      language: 'bash'
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedLanguage(newValue);
  };

  return (
    <Box>
      {/* Navigation - Existing nav will be here */}
      
      {/* Introduction Section */}
      <Box sx={{ 
        minHeight: '80vh', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #0f172a 0%, #1e293b 50%, #0f172a 100%)',
        position: 'relative',
        overflow: 'hidden',
        py: 8,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(82, 183, 136, 0.15) 0%, rgba(82, 183, 136, 0.05) 50%, rgba(15, 23, 42, 0) 100%)',
          pointerEvents: 'none'
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(45deg, rgba(82, 183, 136, 0.1) 0%, rgba(15, 23, 42, 0) 100%)',
          pointerEvents: 'none'
        }
      }}>
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Box sx={{ textAlign: 'center', maxWidth: '900px', mx: 'auto' }}>
            <Typography 
              variant="h1" 
              component="h1" 
              gutterBottom 
              sx={{ 
                color: '#52b788',
                fontSize: { xs: '3rem', md: '5rem' },
                fontWeight: 800,
                letterSpacing: '-0.02em',
                mb: 3,
                lineHeight: 1.1
              }}
            >
              Frontier AI Models and Agents for Developers
            </Typography>
            <Typography 
              variant="h4" 
              paragraph 
              sx={{ 
                color: 'rgba(255, 255, 255, 0.9)',
                mb: 6,
                fontSize: { xs: '1.25rem', md: '1.5rem' },
                fontWeight: 500,
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6
              }}
            >
              Build modern AI apps with state-of-the-art LLMs and agents, using our simple yet powerful API.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/signup"
              sx={{ 
                bgcolor: '#52b788',
                color: 'white',
                px: 6,
                py: 2,
                fontSize: '1.25rem',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#40916c'
                }
              }}
            >
              Get Started
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Section 1: Features */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#52b788' }}>
            Our AI Services
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {[
              {
                title: 'LLM Inference',
                description: 'Access top performing language models like Llama and Mistral',
                icon: <ModelsIcon fontSize="large" sx={{ color: '#52b788' }} />
              },
              {
                title: 'Agents (Coming Soon)',
                description: 'Create and deploy powerful AI agents that seamlessly integrate your business logic and application code with Frontier LLMs.',
                icon: <ModelsIcon fontSize="large" sx={{ color: '#52b788' }} />
              },
              {
                title: 'Embeddings',
                description: 'Build powerful RAG applications with our high-performance embedding models',
                icon: <APIIcon fontSize="large" sx={{ color: '#52b788' }} />
              }
            ].map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card elevation={3} sx={{ 
                  height: '100%', 
                  bgcolor: '#1e293b',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {feature.icon}
                      <Typography variant="h5" sx={{ ml: 2, color: '#52b788' }}>
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#52b788' }}>
            Simple, Transparent Pricing
          </Typography>
          <Typography variant="h6" align="center" sx={{ color: 'rgba(255, 255, 255, 0.9)', mb: 6 }}>
            Pay only for what you use. No hidden fees.
          </Typography>
          <Grid container spacing={4}>
            {/* LLM Models Pricing */}
            <Grid item xs={12} md={6}>
              <Card sx={{ 
                height: '100%', 
                bgcolor: '#1e293b',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <CardContent>
                  <Typography variant="h5" sx={{ color: '#52b788', mb: 2 }}>
                    LLM Models
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
                      Llama-3.3-70B: $0.8 per 1M tokens
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
                      Llama-3.2-8B: $0.2 per 1M tokens
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
                      Mistral-Nemo: $0.3 per 1M tokens
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    Same price for both input and output tokens
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Embeddings Pricing */}
            <Grid item xs={12} md={6}>
              <Card sx={{ 
                height: '100%', 
                bgcolor: '#1e293b',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <CardContent>
                  <Typography variant="h5" sx={{ color: '#52b788', mb: 2 }}>
                    Embeddings
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="body1" sx={{ color: 'white', mb: 2 }}>
                      Snowflake Arctic Embed L v2.0: $0.015 per 1M tokens
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    High-quality embeddings at an affordable price
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* LLM Inference Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                LLM Inference
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Harness the power of advanced language models for your applications
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'Access State-of-the-art multilingual LLMs including Meta Llama and Mistral Nemo',
                  'Low-latency inference with global distribution',
                  'OpenAI compatible API for easy integration',
                  'Good rate limits and competitive pricing'
                ].map((point, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ModelsIcon color="primary" sx={{ mr: 2 }} />
                    <Typography variant="body1">{point}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/llama-in-a-cloud.png"
                alt="LLM Inference"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
            </Grid>
          </Grid>
          
          {/* Code Example Section */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#52b788', textAlign: 'center', mb: 4 }}>
              Make Your First API Call
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs
                value={selectedLanguage}
                onChange={handleTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#52b788',
                  },
                  '& .MuiTab-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-selected': {
                      color: '#52b788',
                    },
                  },
                }}
              >
                <Tab label="Node.js" value="nodejs" />
                <Tab label="Python" value="python" />
                <Tab label="cURL" value="curl" />
              </Tabs>
            </Box>
            <Box sx={{ 
              bgcolor: '#1e293b',
              borderRadius: 2,
              overflow: 'hidden',
              '& pre': {
                margin: 0,
                borderRadius: 2,
              }
            }}>
              <SyntaxHighlighter
                language={codeExamples[selectedLanguage].language}
                style={oneDark}
                customStyle={{
                  margin: 0,
                  padding: '20px',
                  backgroundColor: '#1e293b',
                }}
              >
                {codeExamples[selectedLanguage].code}
              </SyntaxHighlighter>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Agents Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center" direction="row-reverse">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                AI Agents (Coming Soon)
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Supercharge your applications with intelligent AI agents
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'Define your own functionalities with python code',
                  'Advanced reasoning and decision-making capabilities',
                  'Support for the best frontier models including o1, GPT4o, Lllama, Claude and Gemini',
                  'Built-in memory and context management',
                  'Scalable and production-ready'
                ].map((point, index) => (
                  <Box key={index} sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    p: 2,
                    bgcolor: '#1e293b',
                    borderRadius: 1,
                    boxShadow: 1
                  }}>
                    <APIIcon sx={{ mr: 2, color: '#52b788' }} />
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/agent.webp"
                alt="AI Agents"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Button
                  variant="contained"
                  size="large"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=387dYw7CEUi88Tjy-nbrEY3xpm5clqFMr_nP8bhBVw5UNkczTFJYMkhHSE00UzlSNVQ2SU4xWEFZSS4u"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    bgcolor: '#2d6a4f',
                    color: 'white',
                    px: 4,
                    py: 1.5,
                    fontSize: '1.1rem',
                    '&:hover': {
                      bgcolor: '#1b4332'
                    }
                  }}
                >
                  Join the Waitlist
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Embeddings Detailed Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom sx={{ color: '#52b788' }}>
                Embeddings
              </Typography>
              <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.9)' }} paragraph>
                Build powerful RAG applications with semantic search capabilities
              </Typography>
              <Box sx={{ mb: 4 }}>
                {[
                  'High-performance embedding models optimized for RAG',
                  'Efficient semantic search and retrieval',
                  'Support for multiple document formats',
                  'Easy integration with popular vector databases',
                  'Optimized for production workloads'
                ].map((point, index) => (
                  <Box key={index} sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    p: 2,
                    bgcolor: '#1e293b',
                    borderRadius: 1,
                    boxShadow: 1
                  }}>
                    <APIIcon sx={{ mr: 2, color: '#52b788' }} />
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/rag.png"
                alt="Embeddings and RAG"
                sx={{
                  width: '100%',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              />
            </Grid>
          </Grid>

          {/* Embeddings Code Example Section */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#52b788', textAlign: 'center', mb: 4 }}>
              Generate Embeddings
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
              <Tabs
                value={selectedLanguage}
                onChange={handleTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#52b788',
                  },
                  '& .MuiTab-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-selected': {
                      color: '#52b788',
                    },
                  },
                }}
              >
                <Tab label="Node.js" value="nodejs" />
                <Tab label="Python" value="python" />
                <Tab label="cURL" value="curl" />
              </Tabs>
            </Box>
            <Box sx={{ 
              bgcolor: '#1e293b',
              borderRadius: 2,
              overflow: 'hidden',
              '& pre': {
                margin: 0,
                borderRadius: 2,
              }
            }}>
              <SyntaxHighlighter
                language={
                  selectedLanguage === 'nodejs' ? 'javascript' :
                  selectedLanguage === 'python' ? 'python' : 'bash'
                }
                style={oneDark}
                customStyle={{
                  margin: 0,
                  padding: '20px',
                  backgroundColor: '#1e293b',
                }}
              >
                {selectedLanguage === 'nodejs' ? 
                  `import OpenAI from "openai";
const openai = new OpenAI({
    baseURL: "https://api.llamacloud.co",
    apiKey: "Your-API-Key"
});
const embedding = await openai.embeddings.create({
    model: "snowflake-arctic-embed-l-v2.0",
    input: "Your text string goes here"
});
const vector = embedding.data[0].embedding;` :
                selectedLanguage === 'python' ?
                  `from openai import OpenAI
client = OpenAI(
    base_url="https://api.llamacloud.co",
    api_key="Your-API-Key"
)
embedding = client.embeddings.create(
    model="text-embedding-ada-002",
    input="Your text string goes here"
)
vector = embedding.data[0].embedding` :
                  `curl https://api.llamacloud.co/embeddings \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer $OPENAI_API_KEY" \\
-d '{
    "model": "text-embedding-ada-002",
    "input": "Your text string goes here"
}'`}
              </SyntaxHighlighter>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Section 2: Benefits */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#52b788' }}>
            Why Choose Us
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/gpu.jpg"
                alt="AI Technology"
                sx={{
                  width: '100%',
                  borderRadius: 2
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {[
                  { title: 'Private & Secure', desc: 'All your API calls is secured end to end and we never store your data or responses.' },
                  { title: 'High Throughput & Low Latency', desc: 'We offer super fast and always available inference to give you peace of mind' },
                  { title: 'Cost Effective', desc: 'Pay only for what you use with competitive pricing' }
                ].map((item, index) => (
                  <Paper key={index} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {item.desc}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ py: 8, bgcolor: '#0f172a', color: 'white' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Ready to Get Started?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Join thousands of developers using our API
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            component={RouterLink}
            to="/signup"
            sx={{
              bgcolor: '#52b788',
              color: 'white',
              px: 6,
              py: 2,
              fontSize: '1.25rem',
              fontWeight: 600,
              '&:hover': {
                bgcolor: '#40916c'
              },
              mt: 4
            }}
          >
            Start Building Now
          </Button>
        </Container>
      </Box>

      {/* Quick Links Section */}
      <Box sx={{ py: 8, bgcolor: '#0f172a' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                title: 'Documentation',
                description: 'Comprehensive guides and API references',
                icon: <DocsIcon sx={{ fontSize: 40, color: '#52b788' }} />,
                link: 'https://docs.llamacloud.co'
              },
              {
                title: 'Example Apps',
                description: 'Sample applications and code snippets',
                icon: <ModelsIcon sx={{ fontSize: 40, color: '#52b788' }} />,
                link: 'https://docs.llamacloud.co/examples/chatbot'
              }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box 
                  onClick={() => window.location.href = item.link}
                  sx={{ 
                    cursor: 'pointer'
                  }}
                >
                  <Card sx={{ 
                    height: '100%',
                    bgcolor: '#1e293b',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4
                    }
                  }}>
                    <CardContent sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center',
                      textAlign: 'center'
                    }}>
                      {item.icon}
                      <Typography variant="h5" sx={{ mt: 2, mb: 1, color: 'white' }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ 
        bgcolor: '#1e293b', 
        color: 'white',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        pt: 6,
        pb: 3
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Company Info */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Brilliant AI Technologies LTD
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1, color: '#52b788' }} />
                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
                  5 Brayford Square<br />
                  London, England<br />
                  E1 0SG
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <EmailIcon sx={{ mr: 1, color: '#52b788' }} />
                <Link 
                  href="mailto:hello@brilliantai.co" 
                  sx={{ 
                    color: 'rgba(255, 255, 255, 0.9)',
                    textDecoration: 'none',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  hello@brilliantai.co
                </Link>
              </Box>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Quick Links
              </Typography>
              {[
                { text: 'About Us', link: 'https://brilliantai.co' },
                { text: 'Documentation', link: 'https://docs.llamacloud.co' },
                { text: 'API Reference', link: 'https://docs.llamacloud.co/api/chat' },
                { text: 'Pricing', link: '/pricing' },
                { text: 'Contact Us', link: '/contact' }
              ].map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Link
                    href={item.link}
                    sx={{
                      color: 'rgba(255, 255, 255, 0.9)',
                      textDecoration: 'none',
                      '&:hover': { color: '#52b788' }
                    }}
                  >
                    {item.text}
                  </Link>
                </Box>
              ))}
            </Grid>

            {/* Social Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom sx={{ color: '#52b788' }}>
                Connect With Us
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Link
                  href="https://x.com/brilliantai_hq"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <TwitterIcon sx={{ fontSize: 28 }} />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/brilliantai-hq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <LinkedInIcon sx={{ fontSize: 28 }} />
                </Link>
                <Link
                  href="https://www.facebook.com/profile.php?id=61564797920098"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    '&:hover': { color: '#52b788' }
                  }}
                >
                  <FacebookIcon sx={{ fontSize: 28 }} />
                </Link>
              </Box>
            </Grid>
          </Grid>

          {/* Copyright */}
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              mt: 4, 
              pt: 3, 
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              color: 'rgba(255, 255, 255, 0.7)' 
            }}
          >
            &copy; {new Date().getFullYear()} Brilliant AI Technologies LTD. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
