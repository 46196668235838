// /Users/johnolafenwa/source/llama-cloud/frontend/src/pages/Login.js

import React, { useEffect, useState, useContext} from 'react';
import { Container, Typography, CircularProgress, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';

function Auth() {
  const { login } = useContext(AuthContext);
  const [message, setMessage] = useState({ type: '', text: '' });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (token) {
        try {
          const response = await api.post('/auth/login', { token });
          const { token: jwtToken } = response.data;

          login(jwtToken);
          navigate('/welcome');
     
          setMessage({ type: 'success', text: 'Login successful! Redirecting...' });
          setTimeout(() => {
            navigate('/welcome');
          }, 2000);
        } catch (error) {
          
          setMessage({ type: 'error', text: error.response?.data?.detail || 'Invalid or expired token.' });
        }
      } else {
        setMessage({ type: 'error', text: 'No token provided.' });
      }
    };
    verifyToken();
  }, [location, navigate]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Logging In
      </Typography>
      {message.text ? (
        <Alert severity={message.type}>{message.text}</Alert>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}

export default Auth;