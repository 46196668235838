import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Alert, 
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Link
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import api from '../services/api';
import { countries } from '../utils/countries';

function Signup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    country: 'United States'
  });
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await api.post('/auth/signup', formData);
      // Navigate to success page immediately
      navigate('/signup-success');
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error.response?.data?.detail || 'Error creating account. Please try again.' 
      });
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sign Up
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Create your account to get started. No password required!
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary" paragraph>
        Already have an account? <Link component={RouterLink} to="/login" color="success.main">Login</Link>
      </Typography>
      {message.text && <Alert severity={message.type} sx={{ mb: 2 }}>{message.text}</Alert>}
      <Box component="form" onSubmit={handleSignup} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Email Address"
          name="email"
          variant="outlined"
          type="email"
          required
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Full Name"
          name="name"
          variant="outlined"
          required
          value={formData.name}
          onChange={handleChange}
        />
        <FormControl fullWidth required>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button 
          type="submit" 
          variant="contained" 
          color="success"
        >
          Create Account
        </Button>
      </Box>
    </Container>
  );
}

export default Signup;
