import React, { useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import {
    Container,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Box,
    Typography,
    Alert,
    CircularProgress,
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import MetricsCard from '../components/admin/MetricsCard';
import ModelMetricsTable from '../components/admin/ModelMetricsTable';
import { RequestsChart, TokensChart } from '../components/admin/MetricsCharts';
import { fetchMetrics } from '../services/adminService';

const AdminDashboard = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const [timeWindow, setTimeWindow] = useState(30); // 30 minutes default
    const [metrics, setMetrics] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const models = [
        'llama-3.3-70b',
        'llama-3.2-8b',
        'mistral-nemo',
        'snowflake-arctic-embed-l-v2.0'
    ];

    useEffect(() => {
        const loadMetrics = async () => {
            try {
                setLoading(true);
                setError(null);
                
                // Fetch metrics for each model
                const modelMetrics = {};
                for (const model of models) {
                    const data = await fetchMetrics({
                        last_minutes: timeWindow,
                        model: model
                    });
                    modelMetrics[model] = data;
                }
                
                setMetrics(modelMetrics);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadMetrics();
        const interval = setInterval(loadMetrics, 60000); // Refresh every minute
        
        return () => clearInterval(interval);
    }, [timeWindow]);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    const calculateTotalStats = () => {
        if (!metrics) return {
            totalRequests: 0,
            successRate: 0,
            avgDuration: 0,
            totalTokens: 0
        };
        
        let totalRequests = 0;
        let totalSuccessful = 0;
        let totalDuration = 0;
        let durationCount = 0;
        let totalTokens = 0;

        Object.values(metrics).forEach(({ stats }) => {
            if (stats) {
                totalRequests += stats.total_requests || 0;
                totalSuccessful += stats.successful_requests || 0;
                if (stats.average_duration) {
                    totalDuration += stats.average_duration;
                    durationCount++;
                }
                totalTokens += (stats.total_prompt_tokens || 0) + (stats.total_completion_tokens || 0);
            }
        });

        return {
            totalRequests,
            successRate: totalRequests > 0 ? totalSuccessful / totalRequests : 0,
            avgDuration: durationCount > 0 ? totalDuration / durationCount : 0,
            totalTokens
        };
    };

    const totalStats = calculateTotalStats();

    if (loading) {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Admin Dashboard
                </Typography>
                
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Time Window</InputLabel>
                    <Select
                        value={timeWindow}
                        label="Time Window"
                        onChange={(e) => setTimeWindow(e.target.value)}
                    >
                        <MenuItem value={5}>Last 5 minutes</MenuItem>
                        <MenuItem value={15}>Last 15 minutes</MenuItem>
                        <MenuItem value={30}>Last 30 minutes</MenuItem>
                        <MenuItem value={60}>Last hour</MenuItem>
                        <MenuItem value={360}>Last 6 hours</MenuItem>
                        <MenuItem value={1440}>Last 24 hours</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/* Overview Cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <MetricsCard
                        title="Total Requests"
                        value={totalStats.totalRequests.toLocaleString()}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MetricsCard
                        title="Success Rate"
                        value={`${(totalStats.successRate * 100).toFixed(1)}%`}
                        color="success.main"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MetricsCard
                        title="Avg Response Time"
                        value={`${totalStats.avgDuration.toFixed(2)}s`}
                        color="info.main"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <MetricsCard
                        title="Total Tokens"
                        value={totalStats.totalTokens.toLocaleString()}
                        color="secondary.main"
                    />
                </Grid>
            </Grid>

            {/* Model Performance Table */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Model Performance
                </Typography>
                <ModelMetricsTable modelStats={metrics || {}} />
            </Box>

            {/* Charts */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <RequestsChart modelStats={metrics || {}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TokensChart modelStats={metrics || {}} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default AdminDashboard;
