import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const transformDataForCharts = (modelStats) => {
    return Object.entries(modelStats).map(([model, data]) => {
        const stats = data?.stats || {};
        const isEmbedding = model === 'snowflake-arctic-embed-l-v2.0';
        return {
            name: model,
            successful: stats.successful_requests || 0,
            failed: (stats.total_requests || 0) - (stats.successful_requests || 0),
            inputTokens: stats.total_prompt_tokens || 0,
            // Only include output tokens for non-embedding models
            outputTokens: isEmbedding ? 0 : (stats.total_completion_tokens || 0)
        };
    });
};

export const RequestsChart = ({ modelStats }) => {
    const data = transformDataForCharts(modelStats);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Request Distribution
                </Typography>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="name" 
                                angle={-45}
                                textAnchor="end"
                                height={100}
                                interval={0}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="successful" stackId="a" fill="#4caf50" name="Successful" />
                            <Bar dataKey="failed" stackId="a" fill="#f44336" name="Failed" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export const TokensChart = ({ modelStats }) => {
    const data = transformDataForCharts(modelStats);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Token Usage
                </Typography>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="name" 
                                angle={-45}
                                textAnchor="end"
                                height={100}
                                interval={0}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="inputTokens" fill="#2196f3" name="Input Tokens" />
                            <Bar dataKey="outputTokens" fill="#9c27b0" name="Output Tokens" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};
